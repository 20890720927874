<template>
    <nav class="navbar" :class="{ 'navbar-hidden': !showNavbar, 'dark-mode': isDarkMode }">
        <a class="navbar-brand scaleeffect" href="/">
            <div class="navbar-home-brand">
                <img src="@/assets/logo.png" alt="Logo" />
                <img class='navbar-home-brand-title' :src="titleImage" alt="SYMBIA" />
            </div>
        </a>
        <div class="navbar-links">
            <router-link v-for="(link, index) in links" :key="index" :to="link.path" 
                         class="navbar-item" 
                         :class="{ 'active': $route.path === link.path }">
                <div>{{ link.name }}</div>
            </router-link>
            <button @click="toggleTheme" class="navbar-item theme-toggle">
                <div>{{ isDarkMode ? '☀️' : '🌙' }}</div>
            </button>
        </div>
    </nav>
</template>

<script>
import titleLight from '@/assets/title_light.png';
import titleDark from '@/assets/title_dark.png';

export default {
    name: 'NavBar',
    props: ['isDarkMode'],
    computed: {
        titleImage() {
            return this.isDarkMode ? titleDark : titleLight;
        }
    },
    data() {
        return {
            lastScrollPosition: 0,
            showNavbar: true,
            links: [
                { name: 'Home', path: '/' },
                { name: 'Soluzioni', path: '/solutions' },
                { name: 'Chi Siamo', path: '/about' },
                { name: 'Contatti', path: '/contact' },
            ],
        };
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        toggleTheme() {
            this.$emit('toggle-theme');
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition < 0) {
                return;
            }
            // Hide navbar if scrolling down, show if scrolling up
            this.showNavbar = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition;
        },
        // ... other methods ...
    },
    // ... rest of the component ...
}
</script>

<style scoped>
.navbar {
    background: linear-gradient(135deg, #4BBF9A, #1A876C);
    color: white;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s ease, background 0.3s ease;
    z-index: 1000;
}

.navbar.dark-mode {
    background: linear-gradient(135deg, #30303087, #00000076);
}

.navbar-brand {
    display: flex;
    align-items: center;
    padding: 0 20px;
    text-decoration: none;
}

.navbar-home-brand {
    display: flex;
    align-items: center;
}

.navbar-home-brand img {
    height: 40px;
    margin-right: 10px;
}

.navbar-links {
    display: flex;
    align-items: stretch;
}

.navbar-item {
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: none;
    border-bottom: none;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.navbar-item:not(:last-child) {
    border-right: none;
}

.navbar-item div {
    position: relative;
    z-index: 2;
}

.navbar-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        120deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: all 0.5s ease;
}

.navbar-item:hover::before {
    left: 100%;
}

.navbar-item:hover,
.navbar-item.active {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.theme-toggle {
    font-size: 20px;
}

.navbar-hidden {
    transform: translateY(-100%);
}

@media screen and (max-width: 768px) {
    .navbar-home-brand-title {
        display: none;
    }
    .navbar-item {
        padding: 0 10px;
    }
}
</style>
<template>
  <div class="solutions" :class="{ 'dark-mode': isDarkMode }">
    <h1>Our Solutions</h1>
    <div class="solutions-grid">
      <div class="solution-item" v-for="solution in solutions" :key="solution.id">
        <img :src="solution.icon" :alt="solution.title">
        <h2>{{ solution.title }}</h2>
        <p>{{ solution.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionsPage',
  props: ['isDarkMode'],
  data() {
    return {
      solutions: [
        { id: 1, title: 'AI Integration', description: 'Seamlessly integrate AI into your existing systems.', icon: '@/assets/ai-icon.png' },
        { id: 2, title: 'Data Analytics', description: 'Gain insights from your data with advanced analytics.', icon: '@/assets/analytics-icon.png' },
        { id: 3, title: 'Cloud Solutions', description: 'Scalable and secure cloud infrastructure for your business.', icon: '@/assets/cloud-icon.png' },
        // Add more solutions as needed
      ]
    }
  }
}
</script>

<style scoped>
.solutions {
  padding-top: 80px;
  padding-bottom: 15px;
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

h1 {
  text-align: center;
  color: #1A876C;
  margin-bottom: 40px;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.solution-item {
  background: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.solution-item:hover {
  transform: translateY(-5px);
}

.solution-item img {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.solution-item h2 {
  color: #4BBF9A;
  margin-bottom: 10px;
}

.solution-item p {
  color: #333;
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode h1 {
  color: #4BBF9A;
}

.dark-mode .solution-item {
  background: #1E1E1E;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .solution-item h2 {
  color: #4BBF9A;
}

.dark-mode .solution-item p {
  color: #c2c2c2;
}
</style>
<template>
  <div class="about" :class="{ 'dark-mode': isDarkMode }">
    <h1>About SYMBIA</h1>
    <div class="about-content">
      <div class="about-text">
        <p>SYMBIA is a leading technology company specializing in innovative solutions for businesses. Our mission is to empower organizations with cutting-edge tools and expertise to thrive in the digital age.</p>
        <p>With a team of passionate experts and years of industry experience, we deliver tailored solutions that drive growth and efficiency for our clients.</p>
      </div>
      <div class="about-image">
        <img src="@/assets/logo.png" alt="SYMBIA Team">
      </div>
    </div>
    <div class="values">
      <h2>Our Values</h2>
      <ul>
        <li>Innovation</li>
        <li>Integrity</li>
        <li>Collaboration</li>
        <li>Excellence</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
  props: ['isDarkMode']
}
</script>

<style scoped>
.about {
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 15px;
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

h1, h2 {
  text-align: center;
  color: #1A876C;
}

h1 {
  margin-bottom: 40px;
}

.about-content {
  display: flex;
  gap: 40px;
  margin-bottom: 60px;
}

.about-text, .about-image {
  flex: 1;
}

.about-text p {
  color: #333;
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.values {
  background: linear-gradient(135deg, #4BBF9A, #1A876C);
  color: white;
  padding: 40px;
  border-radius: 8px;
}

.values h2 {
  color: white;
  margin-bottom: 20px;
}

.values ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
}

.values li {
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }
  
  .values ul {
    flex-direction: column;
    align-items: center;
  }
  
  .values li {
    margin-bottom: 10px;
  }
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode h1, .dark-mode h2 {
  color: #4BBF9A;
}

.dark-mode .about-text p {
  color: #c2c2c2;
}

.dark-mode .about-image img {
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
}

.dark-mode .values {
  background: linear-gradient(135deg, #30303087, #00000076);
}
</style>
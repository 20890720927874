<template>
  <div class="container" :class="{ 'dark-mode': isDarkMode }">
    <img class="title" :src="titleImage" alt="SYMBIA">
    <!-- Remove NavBar from here if it exists -->
    <img class="prism" src="@/assets/logo.png" alt="Logo">
    <div class="history-mission" ref="historyMission">
      <div class="history">
        <h1>Chi siamo</h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). </p>
      </div>
      <div class="mission">
        <h1>La mission</h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like). </p>
      </div>
    </div>
    <div class="chevron" v-show="showChevron" @click="scrollToHistoryMission">
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="100" viewBox="0 0 24 24" fill="none" stroke="rgba(0, 0, 0, 0.3)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" filter="url(#blur)">
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </div>
  </div>
</template>

<script>
import titleLight from '@/assets/title_light.png';
import titleDark from '@/assets/title_dark.png';

export default {
  name: 'HomePage',
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleImage() {
      return this.isDarkMode ? titleDark : titleLight;
    }
  },
  mounted() {
    console.log('HomePage mounted, isDarkMode:', this.isDarkMode);
  },
  data() {
    return {
      showChevron: true
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 0) {
        this.showChevron = false;
      }
    },
    scrollToHistoryMission() {
      this.$refs.historyMission.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.container {
  color: #333;
  transition: background-color 0.3s, color 0.3s;
  flex-direction: column;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  width: 80vw;
  margin-top: 100px;
  z-index: 1;
  transition: opacity 0.3s ease;
}

.prism {
  width: 110vw;
  position: relative;
  top: -55vw;
}

.history-mission {
  display: flex;
  position: relative;
  top: -70vw;
  width: 90%;
  justify-content: space-around;
  flex-direction: row;
}
.history h1, .mission h1 {
  color: #333;
  font-size: 2.5em;
}
.history, .mission {
  max-width: 40%;
  text-align: justify;
  color: #555;
}

.chevron {
  position: fixed;
  bottom: 20px; /* Positioned at the bottom of the screen */
  left: 50%;
  transform: translate(-50%, 0);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

.container.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.container.dark-mode .history h1,
.container.dark-mode .mission h1 {
  color: #ffffff;
}

.container.dark.mode .history,
.container.dark.mode .mission {
  color: #c2c2c2;
}

@media (max-width: 768px) {
  .container {
    padding: 100px 0;
  }
  .history-mission {
    flex-direction: column;
    align-items: center;
  }
  .mission h1, .history h1 {
    font-size: 2em;
  }
  .history, .mission {
    max-width: 90%;
  }
}
</style>
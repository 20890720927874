<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <NavBar @toggle-theme="toggleTheme" :isDarkMode="isDarkMode" />
    <router-view :isDarkMode="isDarkMode"></router-view>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  },
  data() {
    return {
      isDarkMode: false
    }
  },
  methods: {
    toggleTheme() {
      console.log('toggleTheme called in App');
      this.isDarkMode = !this.isDarkMode;
      console.log('isDarkMode is now:', this.isDarkMode);
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #ebf8ea;
  transition: background-color 0.3s, color 0.3s;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#app.dark-mode {
  color: #c2c2c2;
  background-color: #121212;
}
</style>
